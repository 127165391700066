import { ModalBase } from "../modal_base";
import {AjaxPromise} from "../../shared/ajax_utilities";
import {Toast} from "../../shared/toast";

export class BulkAssignSimModal extends ModalBase {
    DOM = {};

    constructor() {
        super('bulk_assign_sim_modal');

        this.setupInitialEvents();
    }

    setupDOM($modalTriggerBtn, additionalData) {

        window.dispatchEvent(new Event('RebuildAutocompletes'));
        this.DOM.input = this.MODAL_CONTAINER_EL.find('#iccid-mass-input');
        this.DOM.iccid_errors = this.MODAL_CONTAINER_EL.find('.iccid-errors');
        this.DOM.opr_input = this.MODAL_CONTAINER_EL.find('.sim_opr_ac #operator_id');
        this.DOM.opr_input_name = this.MODAL_CONTAINER_EL.find('.sim_opr_ac #operator_name');

        console.log(this.DOM);
    }

    setupModalEvents($modalTriggerBtn, additionalData) {

        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').on('click', async (e) => {


            e.preventDefault();
            e.stopPropagation();
            this.DOM.input.removeClass('error');
            this.DOM.iccid_errors.html('');
            const value = this.DOM.input.val();
            if(!value || value.length === 0) {
                this.DOM.input.addClass('error');
                return;
            }

            const oprId = this.DOM.opr_input.val();
            if(!oprId || oprId.length === 0) {
                this.DOM.opr_input_name.addClass('error').addClass('required');
                return;
            }

            const blocks = value.split(',');
            let validBlocks = {};
            let invalidBlocks = [];
            blocks.forEach((block, i) => {
                const rangeSplit = block.split('-');
                if(
                    rangeSplit.length === 2 &&
                    (rangeSplit[0].trim()).length === 19 && !isNaN(parseInt(rangeSplit[1].trim())) &&
                    (rangeSplit[1].trim()).length === 19 && !isNaN(parseInt(rangeSplit[1].trim()))
                ) {
                    validBlocks[`blocks[${i}]`] = `${rangeSplit[0].trim()}-${rangeSplit[1].trim()}`;
                    return;
                }

                if((block.trim()).length === 19 && !isNaN(parseInt(block.trim()))) {
                    validBlocks[`blocks[${i}]`] = block.trim();
                    return;
                }

                invalidBlocks.push(block);
                this.fetchErrors(block);
            });

            if(invalidBlocks.length > 0) {
                this.DOM.input.addClass('error');
                return;
            }

            await this.assignIccids(blocks);
        });
    }

    async assignIccids(blocks) {
        try {
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX}assign_sim`,
                method: 'POST',
                data: {
                    operator_id: this.DOM.opr_input.val(),
                    blocks,
                    date_start: this.MODAL_CONTAINER_EL.find('#date_start').val()
                }
            });
            if(res.status !== 'success') {
                this.showAjaxErrors(res.errors);
                return;
            }

            this.MODAL_CONTAINER_EL.find('.modal__btn-primary').addClass('button-disabled').prop('disabled', true);
            Toast.success('Successfully assigned sim card(s)');
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch(err) {
            Toast.error('An unexpected error occurred assigning sim(s)');
        }
    }

    showAjaxErrors(errors) {
        errors.forEach(error => {
            console.log(error);
            if(error.key === 'opr') {
                this.DOM.opr_input_name.addClass('error').addClass('required');
                return;
            }

            this.DOM.iccid_errors.append(`<li>${error.key} - ${error.msg}</li>`);
        });
    }

    fetchErrors(iccid) {
        if(iccid.length !== 19 && iccid.indexOf('-') === -1) {
            this.DOM.iccid_errors.append(`<li>${iccid} - Invalid length for ICC ID must be 19 digits</li>`);
            return;
        }

        if(isNaN(parseInt(iccid)) && iccid.indexOf('-') === -1) {
            this.DOM.iccid_errors.append(`<li>${iccid} - Invalid ICC ID must be numeric</li>`);
            return;
        }

        const splitRange = iccid.split('-');
        if(splitRange.length !== 2) {
            this.DOM.iccid_errors.append(`<li>${iccid} - Invalid range provided</li>`);
            return;
        }

        if(splitRange[0].length !== 19) {
            this.DOM.iccid_errors.append(`<li>${splitRange[0]} - Invalid length must be 19 digits for range ${iccid}</li>`);
            return;
        }

        if(isNaN(parseInt(splitRange[0]))) {
            this.DOM.iccid_errors.append(`<li>${splitRange[0]} - ICC ID must be numeric for range ${iccid}</li>`);
            return;
        }

        if(splitRange[1].length !== 19) {
            this.DOM.iccid_errors.append(`<li>${splitRange[1]} - Invalid length must be 19 digits for range ${iccid}</li>`);
            return;
        }

        if(isNaN(parseInt(splitRange[1]))) {
            this.DOM.iccid_errors.append(`<li>${splitRange[1]} - ICC ID must be numeric for range ${iccid}</li>`);
        }
    }

    setupInitialEvents() {
        this.MODAL_CONTAINER_EL.find('.datepicker').datepicker({
            changeMonth: true,
            changeYear: true,
            showButtonPanel: true,
            dateFormat: 'dd-mm-yy',
        });
    }
}